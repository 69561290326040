body {
    font-family: 'Quicksand', sans-serif;
  }
  
  .nav-font {
    font-family: Helvetica, sans-serif;
    font-size: 16px;
  }
  
  .side-menu {
      background-color: gray;
      margin: 0;
  }
  
  .acitveRow {
    color: white !important;
    background-color: #017b01 !important;
  }
  
  .float-left {
   float: left
  }
  .float-right {
    float: right
  }
  
  .chart-area-selected {
    height: 80vh !important;
  }
  
  .claim-png {
    width: 100%;
    height: 80%;
  }
  
  .pillTab {
    color: white !important
  }
  
  h2 {
    text-align: center;
    color: #444444
  }
  
  .hide {
    display: none;
  }
  
  .icons {
    height: 65px;
  }
  
  @media only screen and (max-width: 800px) {
    .icons {
      width: 50%;
      height: auto;
    }
  }
  
  .user-dropdown:hover {
    cursor: pointer;
  }
  
  .close-selected {
    margin: 10px 15px;
  }
  
  iframe {
    border: none;
    /* min-height: 180px !important; */
    height: 100% !important;
    min-width: 100% !important;
  }
  .nav-text {
    color: rgb(98,98,98);
  }
  
  .nav-text-active {
    color: #fff;
  }
  
  .nav-text-normal {
      color: #000;
  }
  
  .nav-text:hover {
    color: rgb(1,123,1);
  }
  
  .tile-row {
      margin-top: 3vh;
  }
  
  .menu-line {
    color: #84c240
  }
  
  .card-chart:hover {
    cursor: pointer;
    box-shadow: 0 1px 15px 2px #017b01; 
  }
  
  .card {
    border: 0;
    border-radius: 0.1875rem;
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1); 
  }
  .card [data-notify="container"].alert {
    min-width: auto;
    left: unset !important;
    right: unset !important; 
  }
  .card .card-body {
    padding: 15px 15px 10px 15px; 
  }
  .card .card-body.table-full-width {
    padding-left: 0;
    padding-right: 0; 
  }
  .card .card-header {
    padding: 15px 15px 0;
    border: 0; 
  }
  .card .card-header:not([data-background-color]) {
    background-color: transparent; 
  }
  .card .card-header .card-title {
    margin-top: 10px; 
  }
  .card .map {
    border-radius: 0.1875rem; 
  }
  .card .map.map-big {
    height: 400px; 
  }
  .card[data-background-color="orange"] {
    background-color: #f96332; 
  }
  .card[data-background-color="orange"] .card-header {
    background-color: #f96332; 
  }
  .card[data-background-color="orange"] .card-footer .stats {
    color: #FFFFFF; 
  }
  .card[data-background-color="red"] {
    background-color: #FF3636; 
  }
  .card[data-background-color="yellow"] {
    background-color: #FFB236; 
  }
  .card[data-background-color="blue"] {
    background-color: #2CA8FF; 
  }
  .card[data-background-color="green"] {
    background-color: #18ce0f; 
  }
  .card .image {
    overflow: hidden;
    height: 200px;
    position: relative; 
  }
  .card .avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 15px; 
  }
  .card label {
    font-size: 0.8571em;
    margin-bottom: 5px;
    color: #9A9A9A; 
  }
  .card .card-footer {
    background-color: transparent;
    border: 0; 
  }
  .card .card-footer .stats i {
    margin-right: 5px;
    position: relative;
    top: 2px; 
  }
  .card .card-footer .btn {
    margin: 0; 
  }
  
  .card-plain {
    background: transparent;
    box-shadow: none; 
  }
  .card-plain .card-header,
  .card-plain .card-footer {
    margin-left: 0;
    margin-right: 0;
    background-color: transparent; 
  }
  .card-plain:not(.card-subcategories).card-body {
    padding-left: 0;
    padding-right: 0; 
  }
  
  .card-chart .card-header .card-title {
    margin-top: 10px;
    margin-bottom: 0; 
  }
  
  .card-chart .card-header .card-category {
    margin-bottom: 5px; 
  }
  
  .card-chart .table {
    margin-bottom: 0; 
  }
  .card-chart .table td {
    border-top: none;
    border-bottom: 1px solid #e9ecef; 
  }
  
  .card-chart .card-progress {
    margin-top: 30px; 
  }
  
  .card-chart .chart-area {
    height: 190px;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px; 
  }
  
  .card-chart .card-footer {
    margin-top: 15px; 
  }
  .card-chart .card-footer .stats {
    color: #9A9A9A; 
  }
  
  .card-chart .dropdown {
    position: absolute;
    right: 20px;
    top: 20px; 
  }
  .card-chart .dropdown .btn {
    margin: 0; 
  }
  
  .card-user .image {
    height: 120px; 
  }
  
  .card-user .author {
    text-align: center;
    text-transform: none;
    margin-top: -77px; 
  }
  .card-user .author a + p.description {
    margin-top: -7px; 
  }
  
  .card-user .avatar {
    width: 124px;
    height: 124px;
    border: 1px solid #FFFFFF;
    position: relative; 
  }
  
  .card-user .card-body {
    min-height: 240px; 
  }
  
  .card-user hr {
    margin: 5px 15px; 
  }
  
  .card-user .button-container {
    margin-bottom: 6px;
    text-align: center; 
  }
  
  .mt-6 {
    margin-top: 4rem !important;
  }

  .logo-image-small {
      height: 75px;
  }

  .card-hover:hover {
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
    cursor: pointer;
  }

  .report-tile {
      width: 375px;
  }

  .card-overlay {
    pointer-events:none; /* Add a pointer on hover */
  }

  .cursor-pointer {
      cursor: pointer;
  }